import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel } from '@common-src/model/form-control';
import { QueryPageModel } from '@common-src/model/query-model';
import { dateFormat } from '@common-src/filter/date-format';

export class WorkOrderFormEntityModel extends BaseEntityModel {
    @FormControl({
        label: '表单名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    displayName: string = undefined;

    @FormControl({
        label: '表单标识符',
        type: FormControlType.TEXT,
        required: true,
        readonly: true,
        pattern: /^[a-zA-z].*/,
        message: '标识符以字母开头'
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '描述',
        type: FormControlType.TEXT_AREA
    } as FormControlTextModel)
    description: string = undefined;
    deployed: boolean = true;

    createDate: string = undefined;
    updateDate: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '表单名称',
                dataIndex: 'displayName',
                width: 300
            },
            {
                title: '表单标识符',
                dataIndex: 'name'
            },
            {
                title: '描述',
                dataIndex: 'description'
            },
            {
                title: '创建时间',
                dataIndex: 'createDate',
                customRender: (text, record, index) => {
                    return dateFormat(text);
                }
            },
            {
                title: '同步时间',
                dataIndex: 'updateDate',
                customRender: (text, record, index) => {
                    return dateFormat(text);
                }
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
                width: 400
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        return this;
    }

    toService() {
        const data: any = super.toService();
        return data;
    }
}

export class WorkOrderFormQueryModel extends QueryPageModel {
    keyWords: string = '';

    toService() {
        const data: any = super.toService();
        data.keyWords = this.keyWords;
        return data;
    }
}
